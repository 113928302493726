<template>
	<div class="mt-30-pc">

		<search
			:program="program"
			:search="item_search"
			:option="item_search_option"

			@click="getSearch"
		>
		</search>

		<template
			v-if="items.length > 0"
		>
			<table class="table hide-m">
				<colgroup>
					<col width="80px" />
					<col width="auto" />
					<col width="200px" />
				</colgroup>
				<thead>
					<tr>
						<th>No</th>
						<th>제목</th>
						<th>등록일</th>
					</tr>
				</thead>
				<tbody>
					<template
						v-for="(item, index) in list_items"
					>
						<tr
							:key="'item_' + index"
							@click="toDetail(item)"
						>
							<td :rowspan="item.is_on ? 2 : 1">{{ item.no }}</td>
							<td class="td-left " :class="{'bg-base': item.is_on}">
								<div class="justify-space-between items-center">
									<div class="font-weight-bold">{{ item.b_title }}</div>
									<v-icon v-if="item.is_on">mdi mdi-chevron-up</v-icon>
									<v-icon v-else>mdi mdi-chevron-down</v-icon>
								</div>
							</td>
							<td>{{ item.wDate }}</td>
						</tr>
						<tr
							v-if="item.is_on"
							:key="'item_c_' + index"
						>
							<td class="td-left">
								<Viewer
									v-if="item.b_contents"
									:initialValue="item.b_contents"
									class="pa-10 "
								/>
							</td>
							<td>
								<div
									v-for="(file, f_index) in item.files"
									:key="'file_' + index + f_index"
									class="box pa-5 mb-10 text-left"
								>
									<button
										@click="$bus.$emit('download', 'bbs', item.b_code, file.file_name)"
										target="thumb"
									><v-icon small>mdi mdi-file</v-icon> {{ file.original_name }}</button>
								</div>
							</td>
						</tr>
					</template>
				</tbody>
			</table>

			<ul class="hide-pc mt-10 top-line">
				<li
					v-for="item in items"
					:key="'item_' + item.uid"
					class="under-line"
				>
					<div
						class="pa-10 justify-space-between items-center"
						:class="item_content != item.uid ? 'bg-white' : 'bg-base'"
						@click="item_content != item.uid ? item_content = item.uid : item_content = null"
					>
						<span>{{ item.b_title }}</span>
						<v-icon
							v-if="item_content != item.uid"
						>mdi mdi-menu-down</v-icon>
						<v-icon
							v-else
						>mdi mdi-menu-up</v-icon>
					</div>
					<div
						v-if="item_content == item.uid"
						class=" "
					>
						<div class="pa-10 justify-space-between under-line-dashed">
							<span>등록일</span>
							<span>{{ item.wDate }}</span>
						</div>
						<Viewer
							v-if="item.b_contents"
							:initialValue="item.b_contents"
							class="pa-10 "
						/>
						<div
							v-for="(file, f_index) in item.files"
							:key="'file_' + index + f_index"
							class="box pa-5 mb-10"
						>
							<button
								@click="$bus.$emit('download', 'bbs', item.b_code, file.file_name)"
								target="thumb"
							><v-icon small>mdi mdi-file</v-icon> {{ file.original_name }}</button>
						</div>
					</div>
				</li>
			</ul>

			<Pagination
				:program="program"
				:align="'center'"
				:options="item_search"

				@click="getSearch"

				class="mt-auto pa-10"
			></Pagination>
		</template>
		<Empty
			v-else
		>
		</Empty>
	</div>
</template>

<script>

	import '@toast-ui/editor/dist/toastui-editor-viewer.css';
	import { Viewer } from "@toast-ui/vue-editor";
	import Pagination from "../../components/Pagination";
	import Empty from "../Layout/Empty";
	import Search from "../Layout/Search";

	export default {
		name: 'NoticeList'
		,props: ['user', 'codes']
		,components: {Search, Empty, Pagination, Viewer}
		,data: function() {
			return {
				program: {
					name: '공지사항'
					, top: false
					, title: true
					, bottom: true
					, is_container: true
				}
				, item_search:{
					page: 1
					, list_cnt: 10
					, tCnt: 0
					, b_code: this.$route.params.b_code
					, search_type: 'b_title'
				}
				, item_search_option: {
					is_cnt: true
					, search_type: [{ column: 'b_title', name: '제목'}]

				}
				, items: [

				]
				, item_content: null
			}
		}
		, computed: {
			list_items: function(){
				return this.items.filter( (item, index) => {
					item.no = this.item_search.tCnt - (this.item_search.page * this.item_search.list_cnt - this.item_search.list_cnt) - index
					return item
				})
			}
		}
		,methods: {
			getData: async function(){
				this.$bus.$emit('on', true)
				try{
					const result = await this.$request.init({
						method: 'get'
						,url: 'board/getBbsList'
						,data: this.item_search
					})
					if(result.success){
						this.items = result.data.result
						this.$set(this.item_search, 'tCnt', result.data.tCnt)
					}else{
						throw result.message
					}
				}catch (e) {
					console.log(e)
					this.$bus.$emit('notify', { type: 'error', message: e})
				}finally {
					this.$bus.$emit('on', false)
				}
			}
			, getSearch: function(page){
				if(page){
					this.item_search.page = page
				}
				this.getData()
			}
			, toDetail: function(item){
				this.$set(item, 'is_on', !item.is_on)
			}
		}
		,created() {

			this.$bus.$emit('onLoad', this.program)
			this.getData()
		}
	}
</script>