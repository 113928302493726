<template>
	<div class="mt-30-pc mt-10-m">
		<template
			v-if="items.length > 0"
		>
			<table class="table hide-m">
				<colgroup>
					<col width="120px" />
					<col width="auto" />
					<col width="auto" />
					<col width="150px" />
					<col width="180px" />
				</colgroup>
				<thead>
					<tr>
						<th colspan="2">상품명</th>
						<th>후기</th>
						<th>별점</th>
						<th>등록일</th>
					</tr>
				</thead>
				<tbody>
					<tr
						v-for="item in items"
						:key="'item_' + item.uid"

					>
						<td class="td-left">
							<div class="square-100 flex-column justify-center">
								<img :src="item.pdt_info.pdt_img1" @error="$bus.$emit('onErrorImage', $event)" class="object-cover"/>
							</div>
						</td>
						<td
							class="td-left"
						>
							<button
								@click="toItem(item)"
							>{{ item.b_title }}</button>
						</td>
						<td  class="td-left">{{ item.b_contents }}</td>
						<td>
							<template
								v-for="no in 5"
							>
								<v-icon
									:key="'star_' + no"
									:class="item.b_point >= no ? 'color-yellow' : ''"
								>mdi mdi-star</v-icon>
							</template>
						</td>
						<td>{{ item.wDate }}</td>
					</tr>
				</tbody>
			</table>

			<ul
				class="hide-pc"
			>
				<li
					v-for="item in items"
					:key="'item_' + item.uid"
					class="under-line"
				>
					<div
						class="pa-10 justify-space-between items-center"
						:class="item_content != item.uid ? 'bg-white' : 'bg-base'"
						@click="item_content != item.uid ? item_content = item.uid : item_content = null"
					>
						<span>{{ item.b_title }}</span>
						<v-icon
							v-if="item_content != item.uid"
						>mdi mdi-menu-down</v-icon>
						<v-icon
							v-else
						>mdi mdi-menu-up</v-icon>
					</div>
					<div
						v-if="item_content == item.uid"
						class="top-line "
					>
						<div class="pa-10 justify-space-between items-center ">
							<div>
								<template
									v-for="no in 5"
								>
									<v-icon
										:key="'star_' + no"
										:class="item.b_point >= no ? 'color-yellow' : ''"
										small
									>mdi mdi-star</v-icon>
								</template>
							</div>
							<div>{{ item.wDate }}</div>
						</div>
						<div class="square-100 flex-column justify-center">
							<img :src="item.pdt_info.pdt_img1" @error="$bus.$emit('onErrorImage', $event)" class="object-cover"/>
						</div>
						<div class="mt-10 pa-10 top-line-dashed" @click="toItem(item)">{{ item.b_contents }}</div>

					</div>
				</li>
			</ul>

			<Pagination
				:program="program"
				:align="'center'"
				:options="search"

				@click="getSearch"

				class="mt-auto pa-10"
			></Pagination>
		</template>
		<Empty
			v-else
		>
		</Empty>
	</div>
</template>

<script>
import Pagination from "../../components/Pagination";
import Empty from "../Layout/Empty";
export default {
	name: 'ReviewList'
	,
	components: {Empty, Pagination},
	props: ['user']
	,data: function() {
		return {
			program: {
				name: '상품 후기'
				, top: false
				, title: true
				, bottom: true
				, is_container: true
			}
			,search:{
				b_code: 'b_after'
				, is_ajax: true
				, search_type: 'm_id'
				, search_value: this.user.member_id ? this.user.member_id : 'none'
			}
			,items: [

			]
			,item_content: null
		}
	}
	,methods: {
		getData: async function(){
			this.$bus.$emit('on', true)
			try{
				const result = await this.$request.init({
					method: 'get'
					,url: 'board/getBbsList'
					,data: this.search
				})
				if(result.success){
					this.items = result.data.result
					this.$set(this.search, 'total_count', result.data.tCnt)
					this.$bus.$emit('notify', { type: 'success', message: result.message })
				}else{
					this.$bus.$emit('notify', { type: 'error', message: result.message })
				}
			}catch (e) {
				console.log(e)
			}finally {
				this.$bus.$emit('on', false)
			}
		}
		, getSearch: function(page){
			if(page){
				this.search.page = page
			}
			this.getData()
		}
		, toItem: function(item){
			this.$bus.$emit('to', { name: 'ProductDetail', params: { idx: item.pdt_uid }, not_query: true})
		}
	}
	,created() {

		this.$bus.$emit('onLoad', this.program)
		this.getData()
	}
}
</script>